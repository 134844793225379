import {ADMIN_MENU_ITEMS, CUSTOM_FORMULA, FORMULA_BET_DATA, FORMULA_NUMBERS, WHEEL_NUMBERS} from "./constants";
import moment from 'moment-timezone';

const constants = require("./constants")

export const sumChips = (chips) => {
    let sum = 0;
    chips.map((r) => sum += r.chipSelected)
    return sum;
}
export const isIterable = (obj) => {
    // checks for null and undefined
    if (obj == null) {
        return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
}
export const totalChips = (chips) => {
    let sum = 0;
    if(isIterable(chips)){
        for (let [key, value] of chips) {
            sum += sumChips(value)
        }
    }else{
        Object.keys(chips).map((c) => {
            sum += sumChips(chips[c]);
        })
    }

    return sum;
}
export const countChips = (chips) => {
    let sum = 0;
    if(isIterable(chips)){
        for (let [key, value] of chips) {
            sum += value.length
        }
    }else{
        Object.keys(chips).map((c) => {
            sum += chips[c].length;
        })
    }

    return sum;
}
export const uuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};
export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
export const getRightNumbers = (number) => {
    let selectedHover = [];
    if(number % 3 !== 0){
        selectedHover.push(number);
        selectedHover.push(number + 1);
    }
    return selectedHover;
}
export const getTopNumbers = (number) => {
    let selectedHover = [];
    if(number < 3){
        selectedHover.push(number);
        selectedHover.push(0);
    }else{
        selectedHover.push(number);
        selectedHover.push(number - 3);
    }
    return selectedHover;
}
export const getLeftNumbers = (number) => {
    let selectedHover = [];
    if(constants.NUMBERS_OF_LEFT_TABLE.includes(number)){
        selectedHover.push(number);
        selectedHover.push(number + 1);
        selectedHover.push(number + 2);
    }else{
        selectedHover.push(number);
        selectedHover.push(number - 1);
    }
    return selectedHover;
}
export const getCornerNumbers = (number) => {
    let selectedHover = [];
    if(constants.NUMBERS_OF_LEFT_TABLE.includes(number)){
        selectedHover.push(number);
        selectedHover.push(number + 1);
        selectedHover.push(number + 2);
        number = number - 3;
        selectedHover.push(number);
        selectedHover.push(number + 1);
        selectedHover.push(number + 2);
    }else{
        selectedHover.push(number);
        selectedHover.push(number - 1);
        selectedHover.push(number - 3);
        selectedHover.push(number - 4);
        if(number === 2)
            selectedHover.push(0);
    }
    return selectedHover;
}

export const totalWin = (wins) => {
    let total = 0;
    wins.map((r) => total += r.win)
    return total;
}
export const filterFormula = (formula) => {
    if(formula.startsWith("tsplit")){
        let n = parseInt(formula.replaceAll("tsplit-", ""));
        return {
            formula: "split",
            numbers: getTopNumbers(n)
        };
    }
    if(formula.startsWith("split")){
        let n = parseInt(formula.replaceAll("split-", ""));
        return {
            formula: "split",
            numbers: getRightNumbers(n)
        };
    }
    if(formula.startsWith("corner")){
        let n = parseInt(formula.replaceAll("corner-", ""));
        if(n === 1){
            return {
                formula: "first-four",
                numbers: getCornerNumbers(n)
            };
        }
        if(n <= 3){
            return {
                formula: "trio",
                numbers: getCornerNumbers(n)
            };
        }
        if(constants.NUMBERS_OF_LEFT_TABLE.includes(n)){
            return {
                formula: "line",
                numbers: getCornerNumbers(n)
            };
        }
        return {
            formula: "corner",
            numbers: getCornerNumbers(n)
        };
    }
    if(formula.startsWith("street")){
        let n = parseInt(formula.replaceAll("street-", ""));
        return {
            formula: "street",
            numbers: [n, n+1, n+2]
        };
    }
    return {
        formula,
        numbers: constants.FORMULA_NUMBERS[formula]
    };
}
export const isFormula = (key) => {
    if(key.startsWith("tsplit"))
        return true;
    if(key.startsWith("split"))
        return true;
    if(key.startsWith("corner"))
        return true;
    if(key.startsWith("street"))
        return true;
    if(Object.keys(FORMULA_BET_DATA).includes(key))
        return true;
    return false;
}
export const findNumberInFormula = (formula, number) => {
    let numbers = constants.FORMULA_NUMBERS[formula];
    if(!numbers) {
        numbers = filterFormula(formula)['numbers'];
    }
    return (numbers && numbers.includes(number));
}
export const findFormulaInHistory = (historyId, history) => {
    let result = history.filter((f) => f.historyId && f.historyId === historyId)
    return result;
}
export const updateNumbersInHistory = (historyId, history, numbers) => {
    history.forEach((f) => {
        if(f.historyId === historyId)
            f.numbers = numbers;
    })
    return history;
}
export const convertWinBoardToGroup = (winTable) => {
    let rows = {};
    if(winTable && winTable.length > 0){
        winTable.forEach((row) => {
            if(!rows.hasOwnProperty(row.formula)){
                rows[row.formula] = {
                    label: row.label,
                    value: row.value,
                    rate: row.rate,
                    number: row.number,
                    win: row.win,
                    chips: [row]
                };
            }else{
                rows[row.formula].value += row.value;
                rows[row.formula].win += row.win;
            }
        })
    }
    let wins = [];
    Object.keys(rows).forEach((row) => {
        wins.push(rows[row]);
    });
    wins.sort(function(a, b) {
        var keyA = a.win,
            keyB = b.win;
        // Compare the 2 dates
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
    });
    return wins;
}
export const convertChipsToGroup = (chips) => {
    let rows = [];
    for (let [key, value] of chips) {
        let formula = null;
        if(typeof key === "number"){
            formula = {
                numbers: [key],
                formula: "straight"
            }

        }else{
            formula = filterFormula(key);
        }
        let sum = 0;
        value.map((v) => sum += v.chipSelected);
        if(CUSTOM_FORMULA.includes(formula.formula)){
            rows[key] = {
                number: FORMULA_BET_DATA[formula.formula].position,
                value: sum,
                win: sum * FORMULA_BET_DATA[formula.formula].value
            }
            formula.numbers.forEach((n) => {
                if(n >= 0 && chips.has(n)){
                    let k = `straight-${n}`;
                    if(rows.hasOwnProperty(k)){
                        rows[k].value += sum;
                        rows[k].win += (sum * FORMULA_BET_DATA[formula.formula].value);
                    }else{
                        rows[k] = {
                            number: `#${n}`,
                            value: sum,
                            win: sum * FORMULA_BET_DATA[formula.formula].value
                        }
                    }
                }

            })
        }else{
            formula.numbers.forEach((n) => {
                let k = `straight-${n}`;
                if(n >= 0){
                    if(rows.hasOwnProperty(k)){
                        rows[k].value += sum;
                        rows[k].win += (sum * FORMULA_BET_DATA[formula.formula].value);
                    }else{
                        rows[k] = {
                            number: (FORMULA_BET_DATA[formula.formula].position === "#")?`#${n}`:FORMULA_BET_DATA[formula.formula].position,
                            value: sum,
                            win: sum * FORMULA_BET_DATA[formula.formula].value
                        }
                    }
                }

            })
        }

    }
    let records = [];
    Object.keys(rows).forEach((r) => {
        records.push({
            formula: r,
            ...rows[r]
        })
    })
    records.sort(function(a, b) {
        var keyA = a.win,
            keyB = b.win;
        // Compare the 2 dates
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
    });
    return records.slice(0, 5);
}
export const convertChipsToTopChips = (payouts) => {
    let chips = new Map();
    payouts.map((chip) => {
        Object.keys(chip.chips).map(function(key, index) {
            let c = [];
            if(chips.has(key)){
                c = chips.get(key);
            }
            chip.chips[key].map((cc) => {
                c.push(cc)
            })

            chips.set(key, c);

        });

    })
    let rows = [];
    for (let [key, value] of chips) {
        let formula = null;
        if(!isFormula(key)){
            key = parseInt(key);
            formula = {
                numbers: [key],
                formula: "straight"
            }

        }else{
            formula = filterFormula(key);
        }
        let sum = 0;
        value.map((v) => sum += v.chipSelected);
        if(CUSTOM_FORMULA.includes(formula.formula)){
            rows[key] = {
                number: FORMULA_BET_DATA[formula.formula].position,
                value: sum,
                win: sum * FORMULA_BET_DATA[formula.formula].value
            }
            formula.numbers.forEach((n) => {
                if(n >= 0 && chips.has(n.toString())){
                    let k = `straight-${n}`;
                    if(rows.hasOwnProperty(k)){
                        rows[k].value += sum;
                        rows[k].win += (sum * FORMULA_BET_DATA[formula.formula].value);
                    }else{
                        rows[k] = {
                            number: `#${n}`,
                            value: sum,
                            win: sum * FORMULA_BET_DATA[formula.formula].value
                        }
                    }
                }

            })
        }else{
            formula.numbers.forEach((n) => {
                let k = `straight-${n}`;
                if(n >= 0){
                    if(rows.hasOwnProperty(k)){
                        rows[k].value += sum;
                        rows[k].win += (sum * FORMULA_BET_DATA[formula.formula].value);
                    }else{
                        rows[k] = {
                            number: (FORMULA_BET_DATA[formula.formula].position === "#")?`#${n}`:FORMULA_BET_DATA[formula.formula].position,
                            value: sum,
                            win: sum * FORMULA_BET_DATA[formula.formula].value
                        }
                    }
                }

            })
        }
    }
    let records = [];
    Object.keys(rows).forEach((r) => {
        records.push({
            formula: r,
            ...rows[r]
        })
    })
    records.sort(function(a, b) {
        var keyA = a.win,
            keyB = b.win;
        // Compare the 2 dates
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
    });
    return records;
}
export const getChipsByFormula = (chips, number) => {
    let rows = [];
    for (let [key, value] of chips) {
        if(typeof key === "number" && key.toString() === number.toString()){
            value.forEach((chip) => {
                rows.push({
                    id: chip.id,
                    value: chip.chipSelected.toString()
                });
            })
        }else{
            if(key.toString() === number.toString()){
                value.forEach((chip) => {
                    rows.push({
                        id: chip.id,
                        value: chip.chipSelected.toString()
                    });
                })
            }
        }
    }
    return rows;
}
export const sumChipsByFormula = (chips, formula) => {
    let c = chips.get(formula);
    let total = 0;
    if(c)
        c.map((r) => total += r.chipSelected)

    return total;
}
export const calculateWinInFormula = (number) => {
    let wins = [number.toString()];
    Object.keys(FORMULA_NUMBERS).forEach((formula) => {
        if(FORMULA_NUMBERS[formula].includes(number))
            wins.push(formula);
    })
    return wins;
}
export const neonBorder = (wonCells, align, number) => {
    if(wonCells.includes(number.toString())){
        return true;
    }
    if(align === "top" && number >= 4 && wonCells.includes((number - 3).toString())){
        return true;
    }
    if(align === "top" && number > 0 && number < 4 && wonCells.includes("0")){
        return true;
    }
    if(align === "top" && number === "2-to-1-1" && wonCells.includes("34")){
        return true;
    }
    if(align === "top" && number === "2-to-1-2" && wonCells.includes("35")){
        return true;
    }
    if(align === "top" && number === "2-to-1-3" && wonCells.includes("36")){
        return true;
    }
    if(align === "right" && wonCells.includes((number + 1).toString()) && !constants.NUMBERS_OF_LEFT_TABLE.includes(number + 1)){
        return true;
    }
    if(align === "right" && typeof number === "string" && number.startsWith("2-to-1-")){
        let r = number.substr(number.length - 1, 1);
        if(r === "1" && wonCells.includes("2-to-1-2"))
            return true;
        if(r === "2" && wonCells.includes("2-to-1-3"))
            return true;
    }
    if(align === "right" && number % 3 === 0 &&
        ((number >= 3 && number <= 12 && wonCells.includes("1st-12"))
        || (number >= 15 && number <= 24 && wonCells.includes("2nd-12"))
            || (number >= 27 && number <= 36 && wonCells.includes("3rd-12")))){
        return true;
    }
    if(align === "top" && (number.toString() === "2nd-12" && wonCells.includes("1st-12"))){
        return true;
    }
    if(align === "top" && (number.toString() === "3rd-12" && wonCells.includes("2nd-12"))){
        return true;
    }
    if(align === "top" && number.toString() === "EVEN" && wonCells.includes("1-18")) return true;
    if(align === "right" && number.toString() === "1st-12" && wonCells.includes("1-18")) return true;
    if(align === "top" && (number.toString() === "19-36" && wonCells.includes("ODD"))) return true;
    if(align === "top" && (number.toString() === "ODD" && wonCells.includes("BLACK"))) return true;
    if(align === "top" && (number.toString() === "BLACK" && wonCells.includes("RED"))) return true;
    if(align === "top" && (number.toString() === "RED" && wonCells.includes("EVEN"))) return true;
    if(align === "left" && (number.toString() === "1-18" || number.toString() === "EVEN") && wonCells.includes("1st-12")) return true;
    if(align === "left" && (number.toString() === "BLACK" || number.toString() === "RED") && wonCells.includes("2nd-12")) return true;
    if(align === "left" && (number.toString() === "19-36" || number.toString() === "ODD") && wonCells.includes("3rd-12")) return true;

    return false;
}
export const specialCells = (context, number) => {
    let {selectedHoverFormula, selectedHoverActions, selectedHover} = context.state;
    if(!selectedHoverFormula) return false;

    if((selectedHoverFormula === "BLACK" || selectedHoverFormula === "RED") && number === 0){
        return true;
    }
    if(selectedHoverFormula === "BLACK" && constants.RED_NUMBERS_OF_TABLE.includes(number)){
        return true;
    }
    if(selectedHoverFormula === "RED" && constants.BLACK_NUMBERS_OF_TABLE.includes(number)){
        return true;
    }
    if(selectedHoverFormula === "EVEN" && typeof number === "number" && (number % 2 !== 0 || number === 0)){
        return true;
    }
    if(selectedHoverFormula === "ODD" && typeof number === "number" && (number % 2 === 0 || number === 0)){
        return true;
    }
    if(selectedHoverFormula === "favorite" && selectedHover.length > 0 && !selectedHover.includes(number)){
        return true;
    }
    return false;
}

export const handleHoverBet = (wonCells, align, number) => {
    if(wonCells.includes(number)){
        return true;
    }
    if(align === "top" && number >= 4 && wonCells.includes((number - 3))){
        return true;
    }
    if(align === "top" && number > 0 && number < 4 && wonCells.includes(0)){
        return true;
    }
    if(align === "top" && number === "2-to-1-1" && wonCells.includes(34)){
        return true;
    }
    if(align === "top" && number === "2-to-1-2" && wonCells.includes(35)){
        return true;
    }
    if(align === "top" && number === "2-to-1-3" && wonCells.includes(36)){
        return true;
    }
    if(align === "right" && wonCells.includes((number + 1)) && !constants.NUMBERS_OF_LEFT_TABLE.includes(number + 1)){
        return true;
    }
    if(align === "right" && typeof number === "string" && number.startsWith("2-to-1-")){
        let r = number.substr(number.length - 1, 1);
        if(r === "1" && wonCells.includes("2-to-1-2"))
            return true;
        if(r === "2" && wonCells.includes("2-to-1-3"))
            return true;
    }
    if(align === "right" && number % 3 === 0 &&
        ((number >= 3 && number <= 12 && wonCells.includes("1st-12"))
            || (number >= 15 && number <= 24 && wonCells.includes("2nd-12"))
            || (number >= 27 && number <= 36 && wonCells.includes("3rd-12")))){
        return true;
    }
    if(align === "top" && (number.toString() === "2nd-12" && wonCells.includes("1st-12"))){
        return true;
    }
    if(align === "top" && (number.toString() === "3rd-12" && wonCells.includes("2nd-12"))){
        return true;
    }
    if(align === "top" && number.toString() === "EVEN" && wonCells.includes("1-18")) return true;
    if(align === "right" && number.toString() === "1st-12" && wonCells.includes("1-18")) return true;
    if(align === "top" && (number.toString() === "19-36" && wonCells.includes("ODD"))) return true;
    if(align === "top" && (number.toString() === "ODD" && wonCells.includes("BLACK"))) return true;
    if(align === "top" && (number.toString() === "BLACK" && wonCells.includes("RED"))) return true;
    if(align === "top" && (number.toString() === "RED" && wonCells.includes("EVEN"))) return true;
    if(align === "left" && (number.toString() === "1-18" || number.toString() === "EVEN") && wonCells.includes("1st-12")) return true;
    if(align === "left" && (number.toString() === "BLACK" || number.toString() === "RED") && wonCells.includes("2nd-12")) return true;
    if(align === "left" && (number.toString() === "19-36" || number.toString() === "ODD") && wonCells.includes("3rd-12")) return true;
}

export const onHoverBorder = (context, align, number) => {
    let {selectedHover, selectedHoverFormula} = context.state;
    if(context.state.lockTable) return;
    if(!selectedHoverFormula) return false;
    if(selectedHoverFormula === "straight"){
        return handleHoverBet(selectedHover, align, number);
    }
    if(selectedHoverFormula === "1st-12" || selectedHoverFormula === "2nd-12" || selectedHoverFormula === "3rd-12"){
        let n = constants.FORMULA_NUMBERS[selectedHoverFormula];
        if(n[9] + 3 > 36){
            if(align === "top" && [n[0], n[1], n[2], `2-to-1-${(n[9] + 3) - 36}`, `2-to-1-${(n[10] + 3) - 36}`, `2-to-1-${(n[11] + 3) - 36}`].includes(number)) return true;
        }else{
            if(align === "top" && [n[0], n[1], n[2], n[9] + 3, n[10] + 3, n[11] + 3].includes(number)) return true;
        }
        if(align === "left" && [n[0], n[3], n[6], n[9]].includes(number)) return true;
        if(align === "right" && [n[2], n[5], n[8], n[11]].includes(number)) return true;
    }
    if(selectedHoverFormula.startsWith("2-to-1")){
        let n = constants.FORMULA_NUMBERS[selectedHoverFormula];
        if(align === "top" && [n[0], `2-to-1-${(n[n.length - 1] + 3) - 36}`].includes(number)) return true;
        if((align === "left") && constants.NUMBERS_OF_LEFT_TABLE.includes(n[0]) && n.includes(number)) return true;
        if((align === "right") && n.includes(number)) return true;
        if(!constants.NUMBERS_OF_LEFT_TABLE.includes(n[0])){
            let a = [];
            n.forEach((num) => a.push(num - 1));
            if((align === "right") && a.includes(number)) return true;
        }
    }
    if(selectedHoverFormula === "1-18" || selectedHoverFormula === "19-36"){
        let n = constants.FORMULA_NUMBERS[selectedHoverFormula];
        if(n[15] + 3 > 36){
            if(align === "top" && [n[0], n[1], n[2], `2-to-1-${(n[15] + 3) - 36}`, `2-to-1-${(n[16] + 3) - 36}`, `2-to-1-${(n[17] + 3) - 36}`].includes(number)) return true;
        }else{
            if(align === "top" && [n[0], n[1], n[2], n[15] + 3, n[16] + 3, n[17] + 3].includes(number)) return true;
        }
        if(align === "left" && [n[0], n[3], n[6], n[9], n[12], n[15]].includes(number)) return true;
        if(align === "right" && [n[2], n[5], n[8], n[11], n[14], n[17]].includes(number)) return true;
    }
    if(selectedHoverFormula === "split"){
        if(align === "left" && constants.NUMBERS_OF_LEFT_TABLE.includes(selectedHover[0]) && (selectedHover.includes(number))) return true;
        if(selectedHover[1] - selectedHover[0] === 1){
            let r = [selectedHover[1]];
            if(!constants.NUMBERS_OF_LEFT_TABLE.includes(selectedHover[0])){
                r.push(selectedHover[0] - 1)
            }
            if(align === "right" && (r.includes(number))) return true;
            let t = Array.from(selectedHover);
            selectedHover.forEach((w) => {
                if((w + 3) > 36){
                    t.push(`2-to-1-${(w + 3) - 36}`)
                }else{
                    t.push(w + 3)
                }

            })
            if(align === "top" && (t.includes(number))) return true;

        }else if(selectedHover[1] > 0){
            if(align === "left" && constants.NUMBERS_OF_LEFT_TABLE.includes(selectedHover[1]) && (selectedHover.includes(number))) return true;
            let r = Array.from(selectedHover);
            if(!constants.NUMBERS_OF_LEFT_TABLE.includes(selectedHover[0])){
                r.push(selectedHover[0] - 1);
                r.push(selectedHover[1] - 1)
            }
            if(align === "right" && (r.includes(number))) return true;
            if(align === "right" && (r.includes(number))) return true;
            let t = selectedHover[1] + 6;
            if(align === "top" && ([selectedHover[1], (((t) > 36)?`2-to-1-${t - 36}`:t)].includes(number))) return true;
        }else{
            if(selectedHover[0] === 1){
                if(align === "top" && [selectedHover[1], selectedHover[0] + 3, selectedHover[0] + 1, selectedHover[0] + 2].includes(number)) return true;
                if(align === "right" && (selectedHover.includes(number))) return true;
            }else if(selectedHover[0] === 2){
                if(align === "top" && [selectedHover[1], selectedHover[0] + 3, selectedHover[0] - 1, selectedHover[0] + 1].includes(number)) return true;
                if(align === "left" && ([selectedHover[1]].includes(number))) return true;
                if(align === "right" && ([selectedHover[0], selectedHover[1], selectedHover[0] - 1].includes(number))) return true;
            }else{
                if(align === "top" && [selectedHover[1], selectedHover[0] + 3, selectedHover[0] - 1, selectedHover[0] - 2].includes(number)) return true;
                if(align === "left" && ([selectedHover[1]].includes(number))) return true;
                if(align === "right" && ([selectedHover[0], selectedHover[1], selectedHover[0] - 1].includes(number))) return true;
            }

        }

    }
    if(selectedHoverFormula === "street"){
        if(align === "left" && ([selectedHover[0]].includes(number))) return true;
        if(align === "right" && ([selectedHover[2]].includes(number))) return true;
        let t = Array.from(selectedHover);
        selectedHover.forEach((w) => {
            if((w + 3) > 36){
                t.push(`2-to-1-${(w + 3) - 36}`)
            }else{
                t.push(w + 3)
            }
        })
        if(align === "top" && (t.includes(number))) return true;
    }
    if(selectedHoverFormula === "corner"){
        if(align === "left" && constants.NUMBERS_OF_LEFT_TABLE.includes(selectedHover[3]) && (selectedHover.includes(number))) return true;
        if(align === "left" && constants.NUMBERS_OF_LEFT_TABLE.includes(selectedHover[1]) && (selectedHover.includes(number))) return true;
        let r = Array.from([selectedHover[0], selectedHover[2]]);
        if(!constants.NUMBERS_OF_LEFT_TABLE.includes(selectedHover[3])){
            r.push(selectedHover[3] - 1);
            r.push(selectedHover[1] - 1)
        }
        if(align === "right" && (r.includes(number))) return true;
        if(align === "right" && (r.includes(number))) return true;
        if(selectedHover[0] + 3 > 36){
            if(align === "top" && ([selectedHover[2], selectedHover[3], `2-to-1-${(selectedHover[0]+ 3) - 36}`, `2-to-1-${(selectedHover[1]+ 3) - 36}`].includes(number))) return true;
        }else{
            if(align === "top" && ([selectedHover[2], selectedHover[3], selectedHover[0] + 3, selectedHover[1] + 3].includes(number))) return true;
        }
    }
    if(selectedHoverFormula === "line" || selectedHoverFormula === "first-four"){
        if(align === "left" && ([selectedHover[0], selectedHover[3]].includes(number))) return true;
        if(align === "right" && ([selectedHover[2],selectedHover[5]].includes(number))) return true;
        if(selectedHover[0]+ 3 > 36){
            if(align === "top" && ([selectedHover[0]- 3,selectedHover[1]- 3,selectedHover[2]- 3, `2-to-1-${(selectedHover[0]+ 3) - 36}`, `2-to-1-${(selectedHover[1]+ 3) - 36}`,`2-to-1-${(selectedHover[2]+ 3) - 36}`].includes(number))) return true;
        }else{
            if(align === "top" && ([selectedHover[0]- 3,selectedHover[1]- 3,selectedHover[2]- 3, selectedHover[0]+ 3,selectedHover[1]+ 3,selectedHover[2]+ 3].includes(number))) return true;
        }
        if(selectedHover[0]- 3 === -2)
            if(align === "left" && ([0].includes(number))) return true;
    }
    if(selectedHoverFormula === "trio" && selectedHover[0] === 2){
        if(align === "left" && ([selectedHover[1], selectedHover[4]].includes(number))) return true;
        if(align === "right" && ([selectedHover[0], selectedHover[4]].includes(number))) return true;
        if(align === "top" && ([selectedHover[4], selectedHover[0] + 3, selectedHover[1] + 3, selectedHover[0] + 1].includes(number))) return true;
    }
    if(selectedHoverFormula === "trio" && selectedHover[3] === -1){
        if(align === "left" && ([selectedHover[2]].includes(number))) return true;
        if(align === "right" && ([selectedHover[2], selectedHover[1] - 1, selectedHover[0]].includes(number))) return true;
        if(align === "top" && ([selectedHover[2], selectedHover[1] - 1, selectedHover[0] + 3, selectedHover[1] + 3].includes(number))) return true;
    }
    if(selectedHoverFormula === "neighbours"){
        if(align === "left" && (selectedHover.includes(number))) return true;
        let r = Array.from(selectedHover);
        selectedHover.map((n) => (!constants.NUMBERS_OF_LEFT_TABLE.includes(n) && r.push(n - 1)))
        if(align === "right" && (r.includes(number))) return true;
        let t = Array.from(selectedHover);
        selectedHover.forEach((n) => {
            if(n + 3 > 36){
                t.push(`2-to-1-${(n + 3) - 36}`)
            }else{
                t.push(n + 3);
                if(n === 0){
                    t.push(1);
                    t.push(2);
                }
            }

        })
        if(align === "top" && (t.includes(number))) return true;
    }

    return false;
}
export const convertArrayToMap = (array, key) => {
    let map = {};
    array.map((a) => map[a[key]] = a);
    return map;
}
export const isStraight = (n) => {
    // let c_formula = ["corner", "tsplit", "split", "street"];
    if(typeof n === "number")
        return true;
    if(CUSTOM_FORMULA.includes(n))
        return false;
    if(n.startsWith("corner") || n.startsWith("tsplit") || n.startsWith("split") || n.startsWith("street"))
        return false;
    return true;
}
export const convertArrayKeyToMap = (array, key) => {
    if(!array) return null;
    let map = new Map();
    // if(isIterable(array)){
    //     for (let [key, value] of array) {
    //         let n = key;
    //         if(isStraight(n))
    //            n = parseInt(n);
    //         map.set(n, value);
    //     }
    //     return map;
    // }

    Object.keys(array).forEach((key) => {
        let n = key;
        if(isStraight(n))
            n = parseInt(n);
        map.set(n, array[key]);
    });
    return map;
}

export const parseFavorites = (array) => {
    array.map((r) => {
        if(r.chip){
            r.chip = convertArrayKeyToMap(r.chip)
        }
    })
    return array;
}
export const convertFormulaToDisplay = () => {

}

export const getNeighbours = (neighbourBet, number, index) => {
    let n = parseInt(number);
    let min = index - neighbourBet;
    let max = index + neighbourBet;
    if(min < 0) min = (WHEEL_NUMBERS.length + min);
    if(max >= 37) max = WHEEL_NUMBERS.length - max;
    if(max < 0) max = 0 - max;
    let h = [];
    for (let c = 0; c < neighbourBet; c++){
        if((min + c) <= 36){
            h.push(WHEEL_NUMBERS[(min + c)])
        }else{
            h.push(WHEEL_NUMBERS[(neighbourBet - c) - 1])
        }
    }
    h.push(n)
    for (let c = 0; c < neighbourBet; c++){
        if((max - c) >= 0){
            h.push(WHEEL_NUMBERS[(max - c)]);
        }else{
            h.push(WHEEL_NUMBERS[((WHEEL_NUMBERS.length) - (neighbourBet - c))])
        }
    }
    return h;
}
export const parseErrorMessage = (error) => {

    if(error.response && error.response.status !== 200 && error.response.data.hasOwnProperty('message')){
        return error.response.data.message;
    }
    return error.response.statusText;
}
export const accountCodeParse = (code, rtl = false) => {
    if(!rtl)
        return code.substr(0, 3)+" "+code.substr(3, 3)+" "+code.substr(6, 3);
    return code.substr(6, 3)+" "+code.substr(3, 3)+" "+code.substr(0, 3);
}

export const queryStringSerialize = (obj) => {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

export const  numberWithCommas = (x, symbol = false, point = ".") => {
    x = parseFloat(x).toFixed(0);
    let m =  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, point);
    //m = m + ",-"
    if(symbol)
        m = "$ " +m ;
    return m;
}

export const  convertMapToObject = (map) =>{
    const obj = {}
    for (let [k,v] of map)
        obj[k] = v
    return obj
}

export const isValidIBANNumber = (input) => {
    var CODE_LENGTHS = {
        AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
        CH: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
        FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
        HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
        LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
        MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
        RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,
        AL: 28, BY: 28, CR: 22, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
        SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
    };
    var iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
        code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
        digits;
    // check syntax and length
    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        return false;
    }
    // rearrange country code and check digits, and convert chars to ints
    digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
        return letter.charCodeAt(0) - 55;
    });
    // final check
    return mod97(digits);
}

export const  mod97 = (string) => {
    var checksum = string.slice(0, 2), fragment;
    for (var offset = 2; offset < string.length; offset += 7) {
        fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
}

export const pathHasPermission = (path, role, permissions) => {
    let p = permissions.filter((row) => row.path === path);
    if(p.length > 0 && rolesCheck(role, p[0].roles))
        return true;

    return false;
}

export const isHiddenAdminPage = (path) => {
    let p = ADMIN_MENU_ITEMS.filter((row) => row.path === path);
    if(p.length > 0 && p[0].hasOwnProperty('hidden') && p[0].hidden)
        return true;

    return false;
}

export const getBackButton = (path, menu) => {
    let p = menu.filter((row) => row.path === path);
    if(p.length > 0 && p[0].backButton)
        return p[0].backButton;

    return null;
}

export const url = (path, queryString = null) => {
    const params = new URLSearchParams(window.location.search);
    if(params.has('rId'))
        queryString = {
            ...queryString,
            rId: params.get('rId')
        };
    if(params.has('bc'))
        queryString = {
            ...queryString,
            bc: params.get('bc')
        };

    if(queryString)
        return `${path}?${queryStringSerialize(queryString)}`;
    return path;
}

export const back = (url) => {
    const params = new URLSearchParams(window.location.search);
    if(params.has('bc')){
        return decodeURIComponent(params.get('bc'));
    }
    return url;
}

export const age = (date) => {
    let dob = new Date(date);
    let month_diff = Date.now() - dob.getTime();
    let age_dt = new Date(month_diff);
    let year = age_dt.getUTCFullYear();
    return Math.abs(year - 1970);
}

export const rolesCheck = (userRoles, allowed) => {
    let allow = false;
    allowed.forEach((r) => {
        if(userRoles.includes(r))
            allow = true;
    })
    return allow;
}


export const timezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
export const utcTime = (date) => {
    return moment.utc(date, "YYYY-MM-DD HH:mm:ss").tz(timezone());
}