import React, {Component} from 'react';
import styles from "./GameTable.module.css";
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {GameTableProvider} from "../../contexts/GameTableContext"
import UserService from "../../services/user.service";

import {withRouter} from "react-router-dom"
import {
    calculateWinInFormula, convertArrayKeyToMap, convertMapToObject, countChips,
    filterFormula, findFormulaInHistory,
    findNumberInFormula, parseFavorites, sumChips,
    sumChipsByFormula,
    totalChips, updateNumbersInHistory, uuid
} from "../../helpers/helpers";
import {
    BLACK_NUMBERS_OF_TABLE,
    FORMULA_BET_DATA,
    DEFAULT_FAVORITES,
    RED_NUMBERS_OF_TABLE,
    MAX_CHIP_AMOUNT,
    MAX_CHIP_COUNT,
    CUSTOM_FORMULA,
    HOME_WEBSITE
} from "../../helpers/constants";
import {ROUTE_TABLE_LIVE_PLAY} from "../../routers/routes";
import GameTableContainer from "./GameTableContainer";
import DesktopGameTable from "./DesktopGameTable";
import Popup from "../../components/ui/Popup";
import TokenService from "../../services/token.service";
import moment from "moment";
import { ref } from 'yup';
import SoundPlayer from '../../components/ui/SoundPlayer/SoundPlayer';

class GameTable extends Component {
    refChips = React.createRef();
    refTable = React.createRef();
    refMonitor = React.createRef();
    refWheel = React.createRef();
    refPlayer = React.createRef();
    refContainer = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
            selectedHover: [],
            selectedHoverFormula: null,
            selectedHoverActions: null,
            numberHistory: [3, 4, 5, 9, 16, 29, 32, 36, 20, 13, 19],
            lastNumber: 4,
            displayText: null,
            chipSelected: 5,
            neighbourBet: 2,
            chips: new Map(),
            previousChips: null,
            tableHistory: [],
            balance: 1500,
            win: 0,
            lose: [],
            winTable: [],
            lockTable: (props.live)?true:false,
            volume: 0.2,
            voiceVolume: 0.5,
            wonCells: [],
            favorites: DEFAULT_FAVORITES,
            autoRepeat: false,
            table: "won",
            practice: (props.live)?false:true,
            processing: false,
            firstChip: false,
            loading: (props.live)?true:false,
            roundNumber: 1,
            roundRemainTime: null,
            logoutConfirm: false,
            userInfo: null,
            feedStreamName: "",
            wheelId: "",
            error_handled: false,
            errors: {
                socket_connection: false,
                socket_already_connected: false,
                socket_user_blocked: false,
                account_closure: false,
                bet_limitation: false
            },
            session_id: uuid(),
            mobileVersion: props.applicationContext.state.mobileVersion,
            isMobileVersion: props.applicationContext.state.isMobileVersion,
            windowOpened: false,
            windows: {
                chips: false,
                favorite: false,
                topCells: false,
                otherButtons: false,
                stats: false,
                top5: false,
                won: false,
                wheel: false,
                account: false,
                video: false
            },
            timer: "",
            waitNextRound: false,
            locale: props.applicationContext.state.lang,
            playError: false
        }
    }

    componentDidMount = async () => {
        const {live, applicationContext} = this.props;
        if(live){
            await this.syncTableToServer();
        }else{
            this.setState({loading: true})
            window.addEventListener('message', async (event) => {
                if(event.data.action === "authenticated"){
                    TokenService.setUser(event.data.user);
                    TokenService.updateLocalAccessToken(event.data.token);
                    try{
                        if(TokenService.getLocalAccessToken()){
                            let common = await UserService.getCommonData();
                            let favorites = DEFAULT_FAVORITES;
                            if(common['user']['favorite']) {
                                favorites = parseFavorites(JSON.parse(common['user']['favorite']['favorite']));
                            }
                            this.doChangeLanguage(common['user']['locale']);
                            this.setState({
                                favorites,
                                userInfo: common['user'],
                                feedStreamName: common['feed_url'],
                                wheelId: common['wheel_state'],
                                locale: common['user']['locale']
                            });
                        }

                    }catch (e){

                    }
                    this.setState({ loading: false });
                }else if(event.data.action === "unauthenticated"){
                    this.setState({ loading: false });
                }

            });

        }




    }
    handleUserBlockWindow = () => {
        const {history} = this.props;
        TokenService.removeUser();
        history.push("/block/account")
    }
    handleCloseWindow = (name, callback = null) => {
        this.setState({
            windows: {
                ...this.state.windows,
                [name]: false,
                data: null
            },
            windowOpened:false,
            selectedHover: []
        }, () => {
            document.getElementById('root').style['overflow-y'] = "auto"
            if(callback) callback();
        })
    }
    handleOpenWindow = (name, data = null) => {
        if(name !== "won" && name !== "otherButtons" && name !== "account" && name !== "video" && this.state.lockTable) return;
        this.handleCloseAllWindows();
        this.setState({
            windows: {
                ...this.state.windows,
                [name]: true,
                ...(data && {data : data})
            },
            windowOpened:true,
            selectedHover: []
        }, () => document.getElementById('root').style['overflow-y'] = "hidden")
    }
    handleCloseWindow = (name, callback = null) => {
        this.setState({
            windows: {
                ...this.state.windows,
                [name]: false
            },
            windowOpened:false,
            selectedHover: []
        }, () => {
            document.getElementById('root').style['overflow-y'] = "auto"
            if(callback) callback();
        })
    }
    handleCloseAllWindows = () => {
        let windows = this.state.windows;
        if(!windows.account && !windows.video){
            Object.keys(windows).forEach((k) => windows[k] = false);
            this.setState({
                windows,
                windowOpened:false,
                selectedHover: []
            }, () => document.getElementById('root').style['overflow-y'] = "auto")
        }


    }
    doChangeLanguage = async (language, update = false) => {
        const {applicationContext} = this.props;
        this.setState({
            locale: language
        }, async () => {
            applicationContext.i18n.changeLanguage(language);
            localStorage.setItem('lang', language);
            if(update){
                if(this.state.userInfo){
                    let data = await UserService.doUpdateLocale({
                        locale: language
                    });
                    this.setState({
                        userInfo: data.data
                    })
                }


            }
        })
    }
    syncTableToServer = async (common = null) => {
        if(!common) common = await UserService.getCommonData();
        this.doChangeLanguage(common['user']['locale']);
        let favorites = DEFAULT_FAVORITES;
        if(common['user']['favorite']) {
            favorites = parseFavorites(JSON.parse(common['user']['favorite']['favorite']));
        }
        if(common['user']['blacklisted_at']){
            this.handleUserBlockWindow();
        }
        this.setState({
            numberHistory: common['round_history'],
            lastNumber: (common['round_history'].length > 0)?common['round_history'][1]:0,
            roundNumber: common['round'],
            roundRemainTime: common['round_remain_seconds'],
            lockTable: true,
            balance: common['balance'],
            userInfo: common['user'],
            feedStreamName: common['feed_url'],
            wheelId: common['wheel_state'],
            favorites,
            waitNextRound: true,
            loading: false,
            locale: common['user']['locale']
        }, () => {
            this.doPlaySound("waitNextRound")
            //this.handleSocketConnectionErrorFixed(common);
            this.handleAccountClosureError();
        });


    }

    timeBetweenDates = (toDate) => {
        let dateEntered = new Date(toDate);
        let now = new Date();
        let difference = dateEntered.getTime() - now.getTime();

        if (difference > 0) {
            let seconds = Math.floor(difference / 1000);
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);
            let days = Math.floor(hours / 24);

            hours %= 24;
            minutes %= 60;
            seconds %= 60;
            let out = "";
            if(days === 1)
                out += "1 day ";
            else if(days > 1)
                out += days+" days ";
            if(hours < 10) hours = "0"+hours;
            if(minutes < 10) minutes = "0"+minutes;
            if(seconds < 10) seconds = "0"+seconds;
            out += hours+":"+minutes+":"+seconds;
            return out;
        }
        return null;
    }
    handleAccountClosureError = () => {
        if(!this.state.practice && this.state.userInfo && this.state.userInfo['settings'] && this.state.userInfo['settings']['closure_end_at'] && new Date(this.state.userInfo['settings']['closure_end_at']).getTime() > new Date().getTime()){
            this.setState({
                lockTable: true,
                errors: {
                    ...this.state.errors,
                    account_closure: true
                }
            })
            if(!this.refWheel.hasOwnProperty('current'))
                this.refWheel.doStopTime();
            return false;
        }
        if(!this.state.practice && this.state.userInfo && this.state.userInfo['settings'] && this.state.userInfo['settings']['exclusion_end_at'] && new Date(this.state.userInfo['settings']['exclusion_end_at']).getTime() > new Date().getTime()){
            this.setState({
                lockTable: true,
                errors: {
                    ...this.state.errors,
                    account_closure: true
                }
            })
            if(!this.refWheel.hasOwnProperty('current'))
                this.refWheel.doStopTime();
            return false;
        }
        return true;

    }

    handleSocketConnectionError = () => {
        this.setState({
            errors: {
                ...this.state.errors,
                socket_connection: true
            }
        });
    }
    handleBetLimitation = (amount, callback = null) => {
        if(!this.state.practice && this.state.userInfo && this.state.userInfo.bet_limitation){
            if((this.state.userInfo.bet_limitation['bet'] + amount) > this.state.userInfo.bet_limitation['bet_max']){
                this.setState({
                    errors: {
                        ...this.state.errors,
                        bet_limitation: true
                    }
                });
                if(callback)
                    callback();
                return false;
            }
        }
        return true;


    }
    handleSocketAlreadyConnectedWithAnotherDevice = () => {
        this.setState({
            lockTable: true,
            errors: {
                ...this.state.errors,
                socket_already_connected: true
            }
        })
        if(!this.refWheel.hasOwnProperty('current'))
            this.refWheel.doStopTime();
    }
    handleSocketConnectionErrorFixed = (common) => {
        this.setState({
            errors: {
                ...this.state.errors,
                socket_connection: false,
                socket_already_connected: false
            }
        });

    }
    handleScrollToTopInMobile = () => {
        if(this.state.isMobileVersion){
            //document.getElementById('root').scrollTop = 0;
            //console.log(document.getElementById('root').scrollTop)
            // let scrollUpInterval = setInterval(() => {
            //
            // }, 80);
        }
        //document.getElementById('root').scrollTop = 0;
    }

    insertChipOnTable = (number, chipItem, hid, formula) => {
        let {tableHistory, chips} = this.state;
        let c = (chips.has(number))?chips.get(number):[];
        c.push(chipItem);
        if(!formula){
            tableHistory.push({
                ...chipItem,
                number,
                action: "REMOVE"
            });
        }else{

            let history = findFormulaInHistory(hid, tableHistory);
            if(history.length == 0){
                tableHistory.push({
                    historyId: hid,
                    formula,
                    numbers: [{
                        number,
                        ...chipItem
                    }],
                    action: "REMOVE_GROUP"
                });
            }else{
                let numbers = history[0].numbers;
                numbers.push({
                    number,
                    ...chipItem
                })
                tableHistory = updateNumbersInHistory(hid, tableHistory, numbers);
            }
        }

        chips.set(number, c);
        this.setState({
            chips,
            table: "top5",
            firstChip: true,
            tableHistory,
            balance: this.state.balance - chipItem.chipSelected
        })
    }
    handlePlayingError = (common, number, callback) => {
        let wins = [];
        let lose = [];
        let winTotal = 0;
        let balance = common['balance'];
        let newChips = new Map();
        let dropChips = [];
        for(let [key, value] of this.state.chips){
            value.forEach(v => {
                dropChips.push({
                    id: v.id,
                    chipSelected: v.chipSelected,
                    formula: key
                });
            });
            
        }
        let sounds = [number];
        if(BLACK_NUMBERS_OF_TABLE.includes(parseInt(number))) {
            sounds.push("Black");
        }else if(RED_NUMBERS_OF_TABLE.includes(parseInt(number))) {
            sounds.push("Red");
        }
        this.refPlayer.playGroup(sounds).then(() => {
            callback(balance, newChips, dropChips, winTotal);
        });
        let self = this;
        this.setState({
            numberHistory: common['round_history'],
            lastNumber: common['round_history'][0],
            winTable: wins,
            win: winTotal,
            previousChips: this.state.chips,
            firstChip: false,
            waitNextRound: false,
            wonCells: calculateWinInFormula(number)
        }, () => {
            this.handleScrollToTopInMobile();
            if((wins.length > 0)){
                setTimeout(() => {
                    if(!self.state.isMobileVersion)
                        this.setState({ table: "won" });
                }, 3000);
            }

        })
    }
    handleCheckWin = (number, callback) => {
        let wins = [];
        let lose = [];
        let winTotal = 0;
        let balance = this.state.balance;
        let newChips = new Map();
        let dropChips = [];
        for(let [key, value] of this.state.chips){
            value.forEach(v => {
                if(typeof key === "number" && key === number){
                    let amount = v.chipSelected * FORMULA_BET_DATA["straight"].value;
                    wins.push({
                        value: v.chipSelected,
                        formula: "straight",
                        win: amount,
                        label: FORMULA_BET_DATA["straight"].description,
                        number: `#${number}`,
                        rate: FORMULA_BET_DATA["straight"].value
                    });
                    winTotal += amount;
                    if(newChips.has(key)){
                        let ch = newChips.get(key);
                        ch.push(v)
                        newChips.set(key, ch);
                    }else{
                        newChips.set(key,[v]);
                    }


                }else if(typeof key !== "number" && findNumberInFormula(key, number)){
                    let f = filterFormula(key);
                    let amount = v.chipSelected * FORMULA_BET_DATA[f.formula].value;

                    wins.push({
                        value: v.chipSelected,
                        formula: f.formula,
                        win: amount,
                        label: FORMULA_BET_DATA[f.formula].description,
                        number: (FORMULA_BET_DATA[f.formula].position === "#")?`#${number}`:FORMULA_BET_DATA[f.formula].position,
                        rate: FORMULA_BET_DATA[f.formula].value
                    });
                    winTotal += amount;
                    if(newChips.has(key)){
                        let ch = newChips.get(key);
                        ch.push(v)
                        newChips.set(key, ch);
                    }else{
                        newChips.set(key,[v]);
                    }
                }else{
                    dropChips.push({
                        id: v.id,
                        chipSelected: v.chipSelected,
                        formula: key
                    });
                }
            })
        }

        let sounds = [number];
        if(BLACK_NUMBERS_OF_TABLE.includes(parseInt(number))) {
            sounds.push("Black");
        }else if(RED_NUMBERS_OF_TABLE.includes(parseInt(number))) {
            sounds.push("Red");
        }
        if(winTotal > 0)
            sounds.push("youWin");
        this.refPlayer.playGroup(sounds).then(() => {
            callback(balance, newChips, dropChips, winTotal);
        });

        let {numberHistory} = this.state;
        numberHistory.unshift(number);
        numberHistory.length = 11;
        let self = this;
        this.setState({
            numberHistory,
            lastNumber: numberHistory[1],
            winTable: wins,
            win: winTotal,
            previousChips: this.state.chips,
            firstChip: false,
            waitNextRound: false,
            wonCells: calculateWinInFormula(number)
        }, () => {
            this.handleScrollToTopInMobile();
            if((wins.length > 0)){
                setTimeout(() => {
                    if(!self.state.isMobileVersion)
                        this.setState({ table: "won" });
                }, 3000);
            }

        })
    }
    handleCheckWinFromServer = (server, callback) => {
        if(!this.state.lockTable) return;
        let wins = [];
        let lose = [];
        let winTotal = 0;
        let balance = this.state.balance;
        let newChips = new Map();
        let dropChips = [];
        let chips = new Map(Object.entries(server.chips));
        for(let [key, value] of chips){
            value.forEach(v => {
                if(typeof key === "number" && key === server.number){
                    let amount = v.chipSelected * FORMULA_BET_DATA["straight"].value;
                    wins.push({
                        value: v.chipSelected,
                        formula: "straight",
                        win: amount,
                        label: FORMULA_BET_DATA["straight"].description,
                        number: `#${server.number}`,
                        rate: FORMULA_BET_DATA["straight"].value
                    });
                    winTotal += amount;
                    if(newChips.has(key)){
                        let ch = newChips.get(key);
                        ch.push(v)
                        newChips.set(key, ch);
                    }else{
                        newChips.set(key,[v]);
                    }


                }else if(typeof key !== "number" && findNumberInFormula(key, server.number)){
                    let f = filterFormula(key);
                    let amount = v.chipSelected * FORMULA_BET_DATA[f.formula].value;

                    wins.push({
                        value: v.chipSelected,
                        formula: f.formula,
                        win: amount,
                        label: FORMULA_BET_DATA[f.formula].description,
                        number: (FORMULA_BET_DATA[f.formula].position === "#")?`#${server.number}`:FORMULA_BET_DATA[f.formula].position,
                        rate: FORMULA_BET_DATA[f.formula].value
                    });
                    winTotal += amount;
                    if(newChips.has(key)){
                        let ch = newChips.get(key);
                        ch.push(v)
                        newChips.set(key, ch);
                    }else{
                        newChips.set(key,[v]);
                    }
                }else{
                    dropChips.push({
                        id: v.id,
                        chipSelected: v.chipSelected,
                        formula: key
                    });
                }
            })
        }

        let sounds = [server.number];
        if(BLACK_NUMBERS_OF_TABLE.includes(parseInt(server.number))) {
            sounds.push("Black");
        }else if(RED_NUMBERS_OF_TABLE.includes(parseInt(server.number))) {
            sounds.push("Red");
        }
        if(winTotal > 0)
            sounds.push("youWin");
        let self = this;
        this.refPlayer.playGroup(sounds).then(() => {
            callback(balance, newChips, dropChips, winTotal);
        });
        let numberHistory = server['round_history'];
        this.setState({
            numberHistory,
            lastNumber: numberHistory[1],
            winTable: wins,
            win: winTotal,
            previousChips: chips,
            firstChip: false,
            waitNextRound: false,
            wonCells: calculateWinInFormula(server.number),
            roundNumber: server['round_number'] + 1
        }, () => {
            this.handleScrollToTopInMobile();
            if((wins.length > 0)){
                setTimeout(() => {
                    if(!self.state.isMobileVersion)
                        this.setState({ table: "won" });
                }, 3000);
            }

        })
        

        
    }
    handleFromServer = (server, callback) => {
        if(!this.state.lockTable) return;
        let wins = server['wins'];
        let winTotal = server['table_win'];
        let balance = this.state.balance;
        let newChips = convertArrayKeyToMap(server['new_chips']);

        let dropChips = server['drop_chips'];

        let chips = convertArrayKeyToMap(server.chips);
        let sounds = [server.number];
        if(BLACK_NUMBERS_OF_TABLE.includes(parseInt(server.number))) {
            sounds.push("Black");
        }else if(RED_NUMBERS_OF_TABLE.includes(parseInt(server.number))) {
            sounds.push("Red");
        }
        if(winTotal > 0)
            sounds.push("youWin");
        this.refPlayer.playGroup(sounds).then(() => {
            callback(balance, newChips, dropChips, winTotal, server['new_balance']);
        });

        let numberHistory = server['round_history'];
        let self = this;
        this.setState({
            numberHistory,
            lastNumber: numberHistory[1],
            winTable: wins,
            win: winTotal,
            previousChips: chips,
            userInfo: {
                ...this.state.userInfo,
                bet_limit: server['bet_limit']
            },
            firstChip: false,
            waitNextRound: false,
            wonCells: calculateWinInFormula(server.number),
            roundNumber: server['round_number'] + 1
        }, () => {
            this.handleScrollToTopInMobile();
            if((wins !== null && wins.length > 0)){
                setTimeout(() => {
                    if(!self.state.isMobileVersion)
                        this.setState({ table: "won" });
                }, 3000);
            }

        })

        
    }
    unlockTable = (chips) => {
        let self = this;
        this.doPlaySound("placeBets").then(() => {
            let autoRepeat = this.state.autoRepeat;
            let previousChips = this.calculateChips();
            let delay = (this.state.chips.size > 0)?2000:0;
            let balance = this.state.balance;
            if(autoRepeat){
                let amount = totalChips(previousChips.chips);
                if(this.state.balance - amount <  0) {
                    this.doPlaySound("chargeCredit");
                    autoRepeat = false;
                }
                let onTable = parseInt(totalChips(this.state.chips) - sumChips(this.state.lose));
                balance = balance + onTable;
                chips = new Map();

            }
            this.setState({
                chips: chips,
                balance,
                tableHistory: [],
                lockTable: false,
                wonCells: [],
                lose: [],
                autoRepeat
            }, () => {
                if(autoRepeat){
                    setTimeout(() => {
                        self.refTable.onBetActionsNumbers(previousChips.numbers, previousChips.chips)
                    }, delay)
                }
            });
        });
        
        
    }
    doWaitForNextRound = () => {
        this.setState({
            waitNextRound: true,
            lockTable: true,
            selectedHover: [],
            selectedHoverFormula: null,
            displayText: null
        }, () => {
            this.handleCloseAllWindows();
        })

    }
    handleChangeVolume = (volume) => {
        this.setState({
            volume
        })
    }
    handleChangeVoiceVolume = (volume) => {
        this.setState({
            voiceVolume: volume
        })
    }
    onUpdateState = (state, callback = null) => {
        this.setState(state, () => {
            if(callback) return callback()
        });
    }
    handleUndo = (e) => {
        let {tableHistory, chips} = this.state;
        if(this.state.lockTable || this.state.processing) return;
        let last = Array.from(tableHistory).pop();
        if(!last) return;
        this.setState({
            processing: true
        }, () => {
            let value = 0;
            if(last.action === "REMOVE"){
                let chipsList = chips.get(last.number);
                chipsList.forEach((c, index) => {
                    if(c.id === last.id){
                        chipsList.splice(index, 1)
                        value = c.chipSelected;
                    }
                })
                if(chipsList.length > 0)
                    chips.set(last.number, chipsList)
                else
                    chips.delete(last.number);
                tableHistory.splice(tableHistory.length - 1, 1)
            }
            if(last.action === "ADD"){
                let chipsList = [];
                if(chips.has(last.number))
                    chipsList = chips.get(last.number);

                value = last.chipSelected * -1;
                if(this.state.balance + value > 0){
                    chipsList.push(last)
                    chips.set(last.number, chipsList)
                    last.action = "REMOVE";
                    tableHistory.unshift(last)
                    tableHistory.splice(tableHistory.length - 1, 1)
                }else{
                    this.doPlaySound("chargeCredit");
                }


            }
            if(last.action === "CLEAR"){
                chips = last.chips;
                value = totalChips(last.chips) * -1;
                tableHistory.splice(tableHistory.length - 1, 1)
            }
            if(last.action === "REMOVE_GROUP"){
                last.numbers.forEach((n) => {
                    let chipsList = chips.get(n.number);
                    chipsList.forEach((c, index) => {
                        if(c.id === n.id){
                            chipsList.splice(index, 1)
                            value += c.chipSelected;
                        }
                    })
                    if(chipsList.length > 0)
                        chips.set(n.number, chipsList)
                    else
                        chips.delete(n.number);
                })

                tableHistory.splice(tableHistory.length - 1, 1)
            }
            if(last.action === "ADD_GROUP"){
                last.chips.forEach((chip) => value += chip.chipSelected)
                value = value * -1;
                if(this.state.balance + value > 0){
                    last.chips.forEach((chip) => {
                        let chipsList = [];
                        if(chips.has(chip.number))
                            chipsList = chips.get(chip.number);
                        chipsList.push(chip)
                        chips.set(chip.number, chipsList)
                    })


                }else{
                    this.doPlaySound("chargeCredit");
                }

                tableHistory.splice(tableHistory.length - 1, 1)

            }
            this.setState({
                chips,
                tableHistory,
                balance: this.state.balance + value,
                processing: false
            })
        })

    }
    handleClear = (e, callback = null) => {
        if(this.state.lockTable && e) return;
        let {tableHistory, chips} = this.state;
        if(!callback){
            tableHistory.push({
                action: "CLEAR",
                chips
            })
        }

        this.setState({
            chips: new Map(),
            tableHistory,
            balance: this.state.balance + totalChips(this.state.chips)
        }, () => {
            console.log(this.state.chips)
            if(callback)
                return callback();
        })
    }
    calculateChips = () => {
        let numbers = [];
        let previousChips = new Map();
        for(let [key, value] of this.state.previousChips){
            numbers.push(key);
            previousChips.set(key, value)
        }
        return {
            numbers,
            chips: previousChips,
        }
    }
    handleRotateFromServer = () => {
        const {applicationContext} = this.props;
        const {lang} = applicationContext.state;
        if(this.state.chips.size > 0){
            UserService.play({
                chips: JSON.stringify(convertMapToObject(this.state.chips)),
                round: this.state.roundNumber,
                wheelId: this.state.wheelId
            }).then((response) => {
                if(lang === "fa")
                    this.doPlaySound("serverReceived")
            }).catch((e) => {
                this.setState({
                    playError: true
                }, () => {
                    console.log("Error =>", this.state.playError)
                })
                console.log(e);
            });
            
            
        }
    }
    doRefreshAfterConnect = async (common) => {
        console.log("Refresh after connected socket, Round:"+this.state.roundNumber);
        try{
            let game = await UserService.getPlayedGame({
                round_number: this.state.roundNumber
            });
            this.refWheel.handleFromServer(game);
        }catch (e){
            console.log(e);
        }
        if(!this.refWheel.isWorking()){
            await this.doRefreshTable();
            //this.refWheel.handleTimerError(common)
        }
        
    }
    handleRepeat = (e) => {
        if(this.state.lockTable || !this.state.previousChips || this.state.processing || this.state.autoRepeat) return;
        this.setState({
            processing: true
        }, () => {
            let self = this;
            let delay = (this.state.chips.size > 0)?2000:0;
            if(!this.state.firstChip){
                let previousChips = this.calculateChips();
                this.handleClear(e, () => {
                    setTimeout(() => {
                        self.refTable.onBetActionsNumbers(previousChips.numbers, previousChips.chips)
                    }, delay)
                })

            }
            setTimeout(() => {
                this.setState({
                    processing: false
                })
            }, delay+1000)
        })

    }
    handleAutoRepeat = (e) => {
        if(e) e.preventDefault();
        this.setState({
            autoRepeat: !this.state.autoRepeat
        })
    }
    handleChangeTable = (table) => {
        this.setState({
            table
        })
    }
    handleCheckValues = (number, amount, chipCount, chipSelected) => {

        if(amount > MAX_CHIP_AMOUNT) {
            this.doPlaySound("maximum_bet_500$");
            return false;
        }
        if(chipCount > MAX_CHIP_COUNT) {
            this.doPlaySound("chipsLimited");
            return false;
        }
        if(this.state.balance - chipSelected <  0) {
            this.doPlaySound("chargeCredit");
            return false;
        }
        if((typeof number === "number" || !CUSTOM_FORMULA.includes(number))){
            if((sumChipsByFormula(this.state.chips, number) + this.state.chipSelected) > 50){
                this.doPlaySound("maximum_bet_50$");
                return false;
            }
        }

        if(chipSelected >= 100 && (typeof number === "number" || !CUSTOM_FORMULA.includes(number))){
            this.doPlaySound("maximum_bet_50$");
            return false;
        }

        if(CUSTOM_FORMULA.includes(number)){
            if(chipSelected < 50){
                this.doPlaySound("C50OR100");
                return false;
            }
            if((sumChipsByFormula(this.state.chips, number) + chipSelected) > 100){
                this.doPlaySound("maximum_bet_100$");
                return false;
            }

        }
        return true;
    }
    handleCheckMultiChips = (numbers, chips) => {
        let amount = totalChips(chips);
        let chipCount = countChips(chips);
        let totalTable = totalChips(this.state.chips);
        let totalChipCount = (countChips(this.state.chips));
        if(!this.handleAccountClosureError())
            return false;
        if(!this.handleBetLimitation((totalTable + amount)))
            return false;

        if(totalTable + amount > MAX_CHIP_AMOUNT) {
            this.doPlaySound("maximum_bet_500$");
            return false;
        }
        if(totalChipCount + chipCount > MAX_CHIP_COUNT) {
            this.doPlaySound("chipsLimited");
            return false;
        }

        if(this.state.balance - amount <  0) {
            this.doPlaySound("chargeCredit");
            return false;
        }
        let success = true;
        let self = this;
        numbers.forEach((number) => {
            if(success){
                let chipSelected = sumChips(chips.get(number));
                if((!CUSTOM_FORMULA.includes(number))){
                    if(chipSelected > 50){
                        self.refPlayer.play("maximum_bet_50$");
                        success = false;
                    }
                }else{
                    if(chipSelected < 50){
                        self.refPlayer.play("C50OR100");
                        success = false;
                    }else{
                        if(chipSelected > 100){
                            self.refPlayer.play("maximum_bet_100$");
                            success = false;
                        }
                    }
                }
            }

        })

        return success;
    }
    handleSocket = (channel, data) => {
        switch (channel){
            case '.user-calculate-completed':
                if(this.refWheel) {
                    this.refWheel.handleFromServer(data);
                }
                break;
            case '.round-created':
                if(this.state.chips.size === 0 && this.refWheel){
                    console.log(data);
                    this.refWheel.handleCheckWinFromServer(data);
                }
                break;
            case '.wheel-state-pushed':
                window.location.reload();
                break;
            case '.round-state-pushed':
                if(data.state === "10" && !this.state.lockTable){
                    console.log("Force lock table");
                    this.refWheel.doStopAndSendToServer();
                }
                if(data.state === "5"){
                    if(this.state.playError){
                        this.setState({
                            playError: false
                        }, () => {
                            this.doRefreshTable(data.number);
                        })
                    }
                    console.log(data)
                }
                if(data.state === "3" && !this.state.waitNextRound){
                    console.log("Ready to lock table you some seconds")
                    this.refWheel.doReadyToDropBall();
                    
                }
                
                break;
            case '.user-blocked':
                this.handleUserBlockWindow();
            break;
        }
    }
    doRefreshTable = async (number = null) => {
        let common = await UserService.getCommonData();
        let favorites = DEFAULT_FAVORITES;
        if(common['user']['favorite']) {
            favorites = parseFavorites(JSON.parse(common['user']['favorite']['favorite']));
        }
        this.doChangeLanguage(common['user']['locale']);
        this.setState({
            favorites,
            userInfo: common['user'],
            feedStreamName: common['feed_url'],
            wheelId: common['wheel_state'],
            locale: common['user']['locale']
        }, () => {
            this.refWheel.handleTimerError(common, number??common['round_history'][0])
        });
    }
    doLockTable = (sendToServer = false) => {
        this.setState({
            lockTable: true,
            selectedHover: [],
            selectedHoverFormula: null,
            displayText: null,
            startRotate: true
        })
        this.handleCloseAllWindows();
        if(sendToServer)
            this.handleRotateFromServer();
    }
    updateRef = (obj, ref) => {
        this[obj] = ref;
    }
    handleUpdateTimer = (timer) => {
        this.setState({
            timer
        })
    }
    handlePlayOnHere = async (e) => {
        const {gameTableContext} = this.props;
        this.setState({
            close_sessions_submitting: true
        })
        let result = await UserService.doCloseAnotherSessions(this.state.session_id);
        if(result.success){
            setTimeout(() => {
                window.location.href = ROUTE_TABLE_LIVE_PLAY;
            }, 2000)
        }
    }
    handleCloseSession = async (e) => {
        window.location.href = HOME_WEBSITE;
    }
    doPlaySound = (name, force = false, loop = false) => {
        return this.refPlayer.play(name, loop);
    }
    doStopSound = () => {
        if(this.refPlayer)
            this.refPlayer.stop();
    }
    render() {
        const {applicationContext, live} = this.props;

        return (
            <React.Fragment>
                {(this.state.loading)?(
                    <div className={styles.LoadingContainer}><img src={`${process.env.PUBLIC_URL}/assets/img/my-roulette-light-logo.png`} width={150}/></div>
                ):(
                    <GameTableProvider value={{
                        state: this.state,
                        onUpdateState: this.onUpdateState,
                        handleCheckWin: this.handleCheckWin,
                        handleCheckWinFromServer: this.handleCheckWinFromServer,
                        handleRotateFromServer: this.handleRotateFromServer,
                        handleFromServer: this.handleFromServer,
                        insertChipOnTable: this.insertChipOnTable,
                        unlockTable: this.unlockTable,
                        handleChangeTable: this.handleChangeTable,
                        handleCheckValues: this.handleCheckValues,
                        handleCheckMultiChips: this.handleCheckMultiChips,
                        handleChangeVolume: this.handleChangeVolume,
                        handleChangeVoiceVolume: this.handleChangeVoiceVolume,
                        handleSocket: this.handleSocket,
                        syncTableToServer: this.syncTableToServer,
                        handleSocketConnectionError: this.handleSocketConnectionError,
                        handleSocketConnectionErrorFixed: this.handleSocketConnectionErrorFixed,
                        handleSocketAlreadyConnectedWithAnotherDevice: this.handleSocketAlreadyConnectedWithAnotherDevice,
                        handlePlayingError: this.handlePlayingError,
                        handleUndo: this.handleUndo,
                        handleClear: this.handleClear,
                        handleRepeat: this.handleRepeat,
                        handleAutoRepeat: this.handleAutoRepeat,
                        updateRef: this.updateRef,
                        handleOpenWindow: this.handleOpenWindow,
                        handleCloseWindow: this.handleCloseWindow,
                        handleCloseAllWindows: this.handleCloseAllWindows,
                        handleUpdateTimer: this.handleUpdateTimer,
                        doWaitForNextRound: this.doWaitForNextRound,
                        handleUserBlockWindow: this.handleUserBlockWindow,
                        handleBetLimitation: this.handleBetLimitation,
                        handleAccountClosureError: this.handleAccountClosureError,
                        doChangeLanguage: this.doChangeLanguage,
                        doRefreshAfterConnect: this.doRefreshAfterConnect,
                        doPlaySound: this.doPlaySound,
                        doStopSound: this.doStopSound,
                        doLockTable: this.doLockTable,
                        refContainer: this.refContainer,
                        refChips: this.refChips,
                        refTable: this.refTable,
                        refMonitor: this.refMonitor
                    }}>
                        <GameTableContainer live={live} ref={(ref) => this.refContainer = ref}>
                            <DesktopGameTable />
                            <SoundPlayer ref={(ref) => this.refPlayer = ref}/>
                        </GameTableContainer>
                    </GameTableProvider>
                )}
                {(this.state.errors.socket_connection || this.state.playError) &&
                <Popup
                    show={true}
                    force={true}
                    disableClose={true}
                    SubmitCaption={applicationContext.translator("Reload")}
                    onSubmit={(e) => window.location.reload() }
                    title={applicationContext.translator("Network Error")}
                    description={applicationContext.translator("Something is temporarily wrong with your network connection. Please make sure you are connected to the internet and then reload your browser")}
                />
                }
                
                {this.state.errors.socket_already_connected &&
                <Popup
                    show={true}
                    force={true}
                    SubmitButtonClass={"btn-success"}
                    SubmitCaption={applicationContext.translator("Yes, I want play here")}
                    CancelCaption={applicationContext.translator("No, close this session")}
                    onSubmit={this.handlePlayOnHere}
                    onClose={this.handleCloseSession}
                    SubmittingLoading={this.state.close_sessions_submitting}
                    title={applicationContext.translator("You already connected")}
                    description={this.state.close_sessions_submitting?applicationContext.translator("Please wait you will connect to table for about 2 seconds later..."):applicationContext.translator("You already playing on live table by another session. Do you want close that session and continue playing in here?")}
                />
                }
                {this.state.errors.account_closure && this.state.userInfo &&
                <Popup
                    show={true}
                    force={true}
                    SubmitButtonClass={"btn-success"}
                    SubmitCaption={applicationContext.translator("OK, Go to my account")}
                    onSubmit={() => {
                        const {history} = this.props;
                        history.push("/account/profile")
                    }}
                    SubmittingLoading={this.state.account_closure}
                    title={applicationContext.translator("You are still on a break")}
                >
                    <span style={{ fontSize: 13 }}>
                        {applicationContext.translator("Dear Player, You are still on a break")},<br/>
                        {applicationContext.translator("You will be able to play from {date}").replaceAll("{date}", moment(new Date(this.state.userInfo.settings['closure_end_at'])).format('dddd, DD MMMM YYYY'))}
                    </span>
                </Popup>
                }
                {this.state.errors.bet_limitation &&
                <Popup
                    show={true}
                    force={true}
                    disableClose={true}
                    SubmitCaption={"Ok"}
                    onSubmit={(e) => {
                        this.setState({
                            lockTable: false,
                            errors: {
                                ...this.state.errors,
                                bet_limitation: false
                            }
                        })
                    }}
                    title={applicationContext.translator("Bet Limits")}
                    description={applicationContext.translator("You have reached your {period} bet-limit of ${value}").replaceAll("{period}", ((this.state.userInfo['settings']['bet_limitation_period'] === "1w")?applicationContext.translator("weekly"):this.state.userInfo['settings']['bet_limitation_period'] + applicationContext.translator(" weeks"))).replaceAll("{value}", this.state.userInfo['settings']['bet_limitation_amount'])}
                />
                }
                <iframe width={1} height={1} src={process.env.REACT_APP_LIVE_URL.replaceAll("/live/play", "").concat("/account/profile")} style={{visibility: "hidden"}}></iframe>

            </React.Fragment>

        );
    }
}

export default withRouter(withApplicationContext(GameTable));