import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withGameTableContext} from "../../../contexts/GameTableContext"
import styles from "../GameTable.module.css";
import classNames from "classnames";
class Timer extends Component {
    refTimer = null;
    constructor(props) {
        super(props);
        this.state = {
            timer: props.gameTableContext.state.timer,
            reverse: true
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {gameTableContext} = this.props;
        if(gameTableContext.state.timer !== this.state.timer){
            const t = gameTableContext.state.timer.split(":")
            const t1 = this.state.timer.split(":"); 
            this.setState({
                timer: gameTableContext.state.timer,
                reverse: (parseInt(t[1]) < parseInt(t1[1]))
            })
        }

    }

    render() {
        const {gameTableContext, customClass, nonZero} = this.props;
        return (
            <div className={classNames(styles.Timer, customClass, !this.state.reverse && styles.UpTimer)}>
                {nonZero?this.state.timer.replaceAll("0:", ""):this.state.timer}
            </div>
        );
    }
}

export default withApplicationContext(withGameTableContext((Timer)));