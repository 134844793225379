import React, {Component} from 'react';
import styles from "../GameTable.module.css";
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withGameTableContext} from "../../../contexts/GameTableContext"
import classNames from "classnames";
import {
    countChips,
    totalChips,
    uuid,
    getRightNumbers,
    getLeftNumbers,
    getTopNumbers,
    getCornerNumbers,
    sumChipsByFormula, neonBorder, specialCells, onHoverBorder, timezone
} from "../../../helpers/helpers";
import RedLozenge from "../../../components/icons/RedLozenge.png";
import BlackLozenge from "../../../components/icons/BlackLozenge.png";
import RedLozengeGlow from "../../../components/icons/RedLozengeGlow.png";
import BlackLozengeGlow from "../../../components/icons/BlackLozengeGlow.png";
import {
    NUMBERS_OF_LEFT_TABLE,
    FORMULA_BET_DATA,
    CUSTOM_FORMULA,
    MAX_CHIP_AMOUNT,
    MAX_CHIP_COUNT,
    FORMULA_NUMBERS, TABLE_NUMBERS, RED_NUMBERS_OF_TABLE
} from "../../../helpers/constants";
import ContextMenu from "../../../components/ui/ContextMenu";
import TableRow from "./TableRow";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Timer from "../Wheel/Timer";
import moment from "moment-timezone";

class Table extends Component {
    timer = null;
    refTimeout = null;
    constructor(props) {
        super(props);
        this.state = {
            contextMenu: false,
            contextMenuNumber: null,
            lock: false,
            lockWheel: false,
            height: "100%",
            display: (props.gameTableContext.state.practice)?
                props.applicationContext.translator("Hi, Welcome to practice table"):
                props.applicationContext.translator("Hi, Welcome to live table"),
            timer: moment(new Date()).tz(timezone()).format('ddd, MMMM DD, YYYY HH:mm:ss')
        }

    }

    getTableHeight = (e) => {
        let h = 140;
        if (window.innerWidth > 720 && window.innerWidth <= 1023){
            h = 176;
        }
        this.setState({
            height: `${window.innerHeight - h}px`
        })
    }
    componentWillUnmount = () => {
        clearTimeout(this.refTimeout)
    }
    componentDidMount() {
        this.getTableHeight();
        window.addEventListener('resize', this.getTableHeight);
        const {gameTableContext} = this.props;
        this.refTimeout = setTimeout(() => {
            this.setState({
                display: "",
                date: moment(new Date()).tz(timezone()).format('ddd, MMMM DD, YYYY'),
                timer: moment(new Date()).tz(timezone()).format('HH:mm:ss')
            }, this.showDateTime);
        }, 5000)
    }
    showDateTime = () => {
        const {gameTableContext, applicationContext} = this.props;
        this.timer = setInterval(() => {
            if(this.state.display === ""){
                this.setState({
                    date: moment(new Date()).tz(timezone()).format('ddd, MMMM DD, YYYY'),
                    timer: moment(new Date()).tz(timezone()).format('HH:mm:ss')
                })
            }

        }, 1000)

    }

    onRightOfCell = (number) => {
        if(typeof number !== "number") return false;
        const {gameTableContext} = this.props;
        if(gameTableContext.state.lockTable) return;
        let numbers = getRightNumbers(number);
        let formula = "split";
        let displayText = `${FORMULA_BET_DATA[formula]['label']}, Your bet x${FORMULA_BET_DATA[formula]['value']}`;
        if(number % 3 === 0){
            if(number <= 12) {
                formula = "1st-12";
                numbers = FORMULA_NUMBERS[formula]
                displayText = `${FORMULA_BET_DATA[formula]['label']}, Your bet x${FORMULA_BET_DATA[formula]['value']}`;
            }
            if(number > 12 && numbers <= 24) {
                formula = "2nd-12";
                numbers = FORMULA_NUMBERS[formula];
                displayText = `${FORMULA_BET_DATA[formula]['label']}, Your bet x${FORMULA_BET_DATA[formula]['value']}`;
            }
            if(number > 24 && numbers <= 36) {
                formula = "3rd-12";
                numbers = FORMULA_NUMBERS[formula];
                displayText = `${FORMULA_BET_DATA[formula]['label']}, Your bet x${FORMULA_BET_DATA[formula]['value']}`;
            }
        }
        this.setState({
            display: displayText
        }, gameTableContext.onUpdateState({
            selectedHover: numbers,
            selectedHoverFormula: formula
        }))

    }
    onLeftOfCell = (number) => {
        if(typeof number !== "number") return false;
        const {gameTableContext} = this.props;
        if(gameTableContext.state.lockTable) return;
        let formula = "street";
        let displayText = `${FORMULA_BET_DATA[formula]["label"]}, Your bet x${FORMULA_BET_DATA[formula]["value"]}`;
        this.setState({
            display: displayText
        }, gameTableContext.onUpdateState({
            selectedHover: getLeftNumbers(number),
            selectedHoverFormula: formula
        }))
    }
    onTopOfCell = (number) => {
        if(typeof number !== "number" || number === 0) return false;
        const {gameTableContext} = this.props;
        if(gameTableContext.state.lockTable) return;
        let formula = "split";
        let displayText = `${FORMULA_BET_DATA[formula]["label"]}, Your bet x${FORMULA_BET_DATA[formula]["value"]}`;
        this.setState({
            display: displayText
        }, gameTableContext.onUpdateState({
            selectedHover: getTopNumbers(number),
            selectedHoverFormula: formula
        }))

    }
    onTopLeftCorner = (number) => {
        if(typeof number !== "number") return false;
        const {gameTableContext} = this.props;
        if(gameTableContext.state.lockTable) return;
        let formula = "corner";
        let displayText = `${FORMULA_BET_DATA[formula]["label"]}, Your bet x${FORMULA_BET_DATA[formula]["value"]}`;
        if(NUMBERS_OF_LEFT_TABLE.includes(number) && number > 1){
            formula = "line";
            displayText = `${FORMULA_BET_DATA[formula]["label"]}, Your bet x${FORMULA_BET_DATA[formula]['value']}`;
        }else{
            if(number === 1) {
                formula = "first-four";
                displayText = `${FORMULA_BET_DATA[formula]["label"]}, Your bet x${FORMULA_BET_DATA[formula]['value']}`;
            }else if(number === 2 || number === 3) {
                formula = "trio";
                displayText = `${FORMULA_BET_DATA[formula]["label"]}, Your bet x${FORMULA_BET_DATA[formula]['value']}`;
            }
        }



        this.setState({
            display: displayText
        }, gameTableContext.onUpdateState({
            selectedHover: getCornerNumbers(number),
            selectedHoverFormula: formula
        }))

    }
    onClearSelected = () => {
        const {gameTableContext} = this.props;
        this.setState({
            display: "",
            date: moment(new Date()).tz(timezone()).format('ddd, MMMM DD, YYYY'),
            timer: moment(new Date()).tz(timezone()).format('HH:mm:ss')
        }, gameTableContext.onUpdateState({
            selectedHover: [],
            selectedHoverFormula: null
        }))
    }
    renderBorders = (number) => {
        const {gameTableContext} = this.props;
        return (
            <React.Fragment>
                {!number.toString().endsWith("-12") && <div className={classNames(styles.TableRightBorder,
                    neonBorder(gameTableContext.state.wonCells, "right", number) && styles.HighLightWonBorder,
                    onHoverBorder(gameTableContext, "right", number) && styles.HighLightHoverBorder,
                )}
                     onMouseMove={(e) => this.onRightOfCell(number)}
                     onMouseLeave={(e) => this.onClearSelected()}
                     onContextMenu={(e) => this.handleOnContextMenu(e, `split-${number}`)}
                     onClick={(e) => (typeof number === "number" && number % 3 !== 0)?this.onBet( `split-${number}`):null}
                     id={`cell-f-split-${number}`}
                >
                    <div className={classNames(styles.VerticalLine)}></div>
                    {this.renderChips(`split-${number}`)}
                </div>}
                {!gameTableContext.state.lockTable && this.state.contextMenu && this.state.contextMenuNumber.toString() === `split-${number}` && <ContextMenu show={true}
                             onClose={this.handleOnContextMenu}
                             number={`split-${number}`}
                />}

                {(NUMBERS_OF_LEFT_TABLE.includes(number)
                    || number.toString().endsWith("1-18")
                    || number.toString().endsWith("19-36")
                    || number.toString().endsWith("EVEN")
                    || number.toString().endsWith("ODD")
                    || number.toString().endsWith("BLACK")
                    || number.toString().endsWith("RED")
                ) &&
                <div className={classNames(styles.TableLeftBorder,
                    neonBorder(gameTableContext.state.wonCells, "left", number) && styles.HighLightWonBorder,
                    onHoverBorder(gameTableContext, "left", number) && styles.HighLightHoverBorder,
                )}
                     onMouseMove={(e) => this.onLeftOfCell(number)}
                     onMouseLeave={(e) => this.onClearSelected()}
                     onContextMenu={(e) => this.handleOnContextMenu(e, `street-${number}`)}
                     onClick={(e) => (typeof number === "number")?this.onBet( `street-${number}`):null}
                     id={`cell-f-street-${number}`}
                >
                    <div className={classNames(styles.VerticalLine)}></div>
                    {this.renderChips(`street-${number}`)}

                </div>}
                {!gameTableContext.state.lockTable && this.state.contextMenu && this.state.contextMenuNumber.toString() === `street-${number}` && <ContextMenu show={true}
                             onClose={this.handleOnContextMenu}
                             number={`street-${number}`}
                />}
                {(number === 0 || number === "2-to-1-1") &&
                <div className={classNames(styles.TableLeftBorder,
                    neonBorder(gameTableContext.state.wonCells, "left", number) && styles.HighLightWonBorder,
                    onHoverBorder(gameTableContext, "left", number) && styles.HighLightHoverBorder
                )}>
                    <div className={classNames(styles.VerticalLine)}></div>
                </div>}

                <div className={classNames(styles.TableTopBorder,
                    neonBorder(gameTableContext.state.wonCells, "top", number) && styles.HighLightWonBorder,
                    onHoverBorder(gameTableContext, "top", number) && styles.HighLightHoverBorder
                )}
                     onMouseMove={(e) => this.onTopOfCell(number)}
                     onMouseLeave={(e) => this.onClearSelected()}
                     onContextMenu={(e) => this.handleOnContextMenu(e, `tsplit-${number}`)}
                     onClick={(e) => (typeof number === "number" && number > 0)?this.onBet( `tsplit-${number}`):null}
                     id={`cell-f-tsplit-${number}`}
                >
                    <div className={classNames(styles.HorizontalLine)}></div>
                    {this.renderChips(`tsplit-${number}`)}
                </div>
                {!gameTableContext.state.lockTable && this.state.contextMenu && this.state.contextMenuNumber.toString() === `tsplit-${number}` && <ContextMenu show={true}
                             onClose={this.handleOnContextMenu}
                             number={`tsplit-${number}`}
                />}

                {(number === "2-to-1-1" ||
                    number === "2-to-1-2" ||
                    number === "2-to-1-3" ||
                    number === "2-to-1-1" ||
                    number === "3rd-12" || number === "19-36"
                ) &&
                <div className={classNames(styles.TableBottomBorder,
                    neonBorder(gameTableContext.state.wonCells, "bottom", number) && styles.HighLightWonBorder,
                    onHoverBorder(gameTableContext, "bottom", number) && styles.HighLightHoverBorder
                )}>
                    <div className={classNames(styles.HorizontalLine)}></div>
                </div>}

                <div className={styles.TableTopLeftCorner}
                     onMouseMove={(e) => this.onTopLeftCorner(number)}
                     onMouseLeave={(e) => this.onClearSelected()}
                     onContextMenu={(e) => this.handleOnContextMenu(e, `corner-${number}`)}
                     onClick={(e) => (typeof number === "number" && number > 0)?this.onBet( `corner-${number}`):null}
                     id={`cell-f-corner-${number}`}
                >
                    {this.renderChips(`corner-${number}`)}
                </div>
                {!gameTableContext.state.lockTable && this.state.contextMenu && this.state.contextMenuNumber.toString() === `corner-${number}` && <ContextMenu show={true}
                             onClose={this.handleOnContextMenu}
                             number={`corner-${number}`}
                />}
            </React.Fragment>
        )
    }

    onSelectFormulas = (formula) => {
        if(this.state.contextMenu) return;
        const {gameTableContext} = this.props;
        let displayText = `${FORMULA_BET_DATA[formula]["label"]}, Your bet x${FORMULA_BET_DATA[formula]["value"]}`;

        this.setState({
            display: displayText
        }, gameTableContext.onUpdateState({
            selectedHover: FORMULA_NUMBERS[formula],
            selectedHoverFormula: formula
        }));
    }
    onSelectCell = (number) => {
        if(this.state.contextMenu) return;
        const {gameTableContext} = this.props;
        if(gameTableContext.state.lockTable) return;
        let n = parseInt(number);

        let displayText = `${FORMULA_BET_DATA["straight"]["label"]}, Your bet x${FORMULA_BET_DATA["straight"]["value"]}`;
        this.setState({
            display: displayText
        }, gameTableContext.onUpdateState({
            selectedHover: [n],
            selectedHoverFormula: "straight"
        }))
    }

    onBet = (number, historyId = null, specialChip = null, context = false, formula = null, playSound = true) => {
        const {gameTableContext} = this.props;
        if((this.state.contextMenu && !context) || this.state.lock) return;
        if(gameTableContext.state.lockTable) return;
        this.setState({
            lock: (historyId)?false:true
        }, () => {
            const {gameTableContext} = this.props;
            let el = (typeof number === "number")?`cell-n-${number}`:`cell-f-${number}`;
            let element = document.getElementById(el).getBoundingClientRect();
            const chipSelected = (specialChip)?specialChip:gameTableContext.state.chipSelected;

            let totalTable = totalChips(gameTableContext.state.chips);
            let amount = (totalTable + chipSelected);
            let chipCount = (countChips(gameTableContext.state.chips) + 1);
            if(!gameTableContext.handleAccountClosureError())
                return;
            if(!gameTableContext.handleBetLimitation(amount, () => this.setState({ lock: false })))
                return;

            //CHECK
            if(amount > MAX_CHIP_AMOUNT) {
                gameTableContext.doPlaySound("maximum_bet_500$");
                this.unlockCell(historyId);
                return;
            }
            if(chipCount > MAX_CHIP_COUNT) {
                gameTableContext.doPlaySound("chipsLimited");
                this.unlockCell(historyId);
                return;
            }
            if(gameTableContext.state.balance - chipSelected <  0) {
                gameTableContext.doPlaySound("chargeCredit");
                this.unlockCell(historyId);
                return;
            }
            if((typeof number === "number" || !CUSTOM_FORMULA.includes(number))){
                if((sumChipsByFormula(gameTableContext.state.chips, number) + chipSelected) > 50){
                    gameTableContext.doPlaySound("maximum_bet_50$");
                    this.unlockCell(historyId);
                    return;
                }
            }

            if(chipSelected >= 100 && (typeof number === "number" || !CUSTOM_FORMULA.includes(number))){
                gameTableContext.doPlaySound("maximum_bet_50$");
                this.unlockCell(historyId);
                return;
            }

            if(CUSTOM_FORMULA.includes(number)){
                if(chipSelected < 50){
                    gameTableContext.doPlaySound("C50OR100");
                    this.unlockCell(historyId);
                    return;
                }
                if((sumChipsByFormula(gameTableContext.state.chips, number) + chipSelected) > 100){
                    gameTableContext.doPlaySound("maximum_bet_100$");
                    this.unlockCell(historyId);
                    return;
                }

            }

            ////////////////////////////////////////////////////////////////////
            let chip = gameTableContext.refChips.getChip(chipSelected);
            let chips = gameTableContext.state.chips;
            let c = (chips.has(number))?chips.get(number):[];
            let x = 0;
            let y = 0;
            if(c.length > 0){
                if(typeof number === "number")
                    x = Math.floor(Math.random() * 10);
                y = Math.floor(Math.random() * 10);
            }
            if(playSound) gameTableContext.doPlaySound("chip");

            let id = "c-"+uuid();
            let hid = (historyId)?historyId:uuid();

            let clone = chip.cloneNode( true );
            clone.classList.add("ChipMove");
            clone.setAttribute('id', id)
            clone.style.top = `${chip.offsetTop}px`;
            clone.style.left = `${chip.offsetLeft}px`;
            clone.style.visibility = 'hidden';
            document.body.appendChild( clone );
            let cloned = document.getElementById(id);
            setTimeout(() => {
                clone.style.visibility = '';
                cloned.style.top = `${element.top + (element.height / 2) - 16}px`;
                cloned.style.left = `${element.left + (element.width / 2) - 16}px`;
                setTimeout(() => {
                    cloned.remove();
                    let chipItem = { id, chipSelected, x, y, formula };
                    gameTableContext.insertChipOnTable(number, chipItem, hid, formula);
                    this.unlockCell(historyId);

                }, 240)
            }, 300)

        })

    }
    handleDropInChipOnTable = () => {

    }
    unlockCell = (historyId) => {
        this.setState({
            lock: false
        });
    }
    unlockWheel = () => {
        this.setState({
            lockWheel: false
        });
    }
    onBetFormula = (formula) => {
        if(this.state.contextMenu) return;
        let numbers = FORMULA_NUMBERS[formula];
        this.onBetNumbers(numbers, formula);
    }
    onBetNumbers = (numbers, formula = null, context = false) => {
        if((this.state.contextMenu && !context) || this.state.lockWheel) return;
        const {gameTableContext} = this.props;
        const {chipSelected, tableHistory} = gameTableContext.state;
        let totalTable = totalChips(gameTableContext.state.chips);
        let amount = (chipSelected * numbers.length);
        let chipCount = (countChips(gameTableContext.state.chips) + numbers.length);
        this.setState({
            lockWheel: true
        }, () => {
            if(!gameTableContext.handleAccountClosureError())
                return;
            if(!gameTableContext.handleBetLimitation((totalTable + amount), () => this.unlockWheel()))
                return;

            if((totalTable + amount) > MAX_CHIP_AMOUNT) {
                gameTableContext.doPlaySound("maximum_bet_500$");
                this.unlockWheel();
                return false;
            }
            if(chipCount > MAX_CHIP_COUNT) {
                gameTableContext.doPlaySound("chipsLimited");
                this.unlockWheel();
                return false;
            }
            if(gameTableContext.state.balance - amount <  0) {
                gameTableContext.doPlaySound("chargeCredit");
                this.unlockWheel();
                return false;
            }
            let success = true;
            numbers.forEach((number) => {
                if(success){
                    if((typeof number === "number" || !CUSTOM_FORMULA.includes(number))){
                        if((sumChipsByFormula(gameTableContext.state.chips, number) + gameTableContext.state.chipSelected) > 50){
                            gameTableContext.doPlaySound("maximum_bet_50$");
                            success = false;
                        }
                    }else{
                        if(gameTableContext.state.chipSelected >= 100 && (typeof number === "number" || !CUSTOM_FORMULA.includes(number))){
                            gameTableContext.doPlaySound("maximum_bet_50$");
                            success = false;
                        }else{
                            if(CUSTOM_FORMULA.includes(number)){
                                if(gameTableContext.state.chipSelected < 50){
                                    gameTableContext.doPlaySound("C50OR100");
                                    success = false;
                                }else{
                                    if((sumChipsByFormula(gameTableContext.state.chips, number) + gameTableContext.state.chipSelected) > 100){
                                        gameTableContext.doPlaySound("maximum_bet_100$");
                                        success = false;
                                    }
                                }
                            }
                        }
                    }
                }

            })
            if(!success) {
                this.unlockWheel();
                return;
            }
            gameTableContext.doPlaySound("chip");
            let historyId = uuid();
            numbers.forEach((number) => {
                let el = (typeof number === "number")?`cell-n-${number}`:`cell-f-${number}`;
                let element = document.getElementById(el).getBoundingClientRect();
                ////////////////////////////////////////////////////////////////////
                let chip = gameTableContext.refChips.getChip(chipSelected);
                let chips = gameTableContext.state.chips;
                let c = (chips.has(number))?chips.get(number):[];
                let x = 0;
                let y = 0;
                if(c.length > 0){
                    if(typeof number === "number")
                        x = Math.floor(Math.random() * 10);
                    y = Math.floor(Math.random() * 10);
                }

                
                let id = "c-"+uuid();
                let hid = (historyId)?historyId:uuid();

                let clone = chip.cloneNode( true );
                clone.classList.add("ChipMove");
                clone.setAttribute('id', id)
                clone.style.top = `${chip.offsetTop}px`;
                clone.style.left = `${chip.offsetLeft}px`;
                clone.style.visibility = 'hidden';
                document.body.appendChild( clone );
                let cloned = document.getElementById(id);
                setTimeout(() => {
                    clone.style.visibility = '';
                    cloned.style.top = `${element.top + (element.height / 2) - 16}px`;
                    cloned.style.left = `${element.left + (element.width / 2) - 16}px`;
                    setTimeout(() => {
                        cloned.remove();
                        let chipItem = { id, chipSelected, x, y, formula };
                        gameTableContext.insertChipOnTable(number, chipItem, hid, formula);
                    }, 240)
                }, 300)
            })
            setTimeout(() => {
                this.unlockWheel();
            }, 800);
        })

    }

    onBetActionsNumbers = (actions, chips, formula = null) => {
        if(this.state.contextMenu) return;
        const {gameTableContext} = this.props;
        if(!gameTableContext.handleCheckMultiChips(actions, chips)) return;
        let historyId = uuid();
        gameTableContext.doPlaySound("chip");
        for(let [key, value] of chips){
            value.map((c) => {
                this.onBet(key, historyId, c.chipSelected, null, false, formula, false)
            })
        }
    }
    renderChips = (number) => {
        const {gameTableContext} = this.props;
        let chips = gameTableContext.state.chips;
        let html = [];
        let extraWidth = (gameTableContext.state.isMobileVersion)?12:16;
        if(chips.has(number)){
            chips.get(number).map((c, index) =>
                html.push(
                    <img src={`${process.env.PUBLIC_URL}/assets/img/chips-${c.chipSelected}.png`}
                         className={classNames(styles.CellChipButton)}
                         style={{left: `calc(50% - ${extraWidth + c.x}px)`, top: `calc(50% - ${extraWidth + c.y}px)`}}
                         key={index}
                         alt={`chips-${c.chipSelected}`}
                         id={c.id}
                    />
                )
            )
        }
        return html;
    }
    handleOnContextMenu = (e, number = null) => {
        if(e) e.preventDefault();
        const {gameTableContext} = this.props;
        if(gameTableContext.state.lockTable) return;
        if(number){
            this.setState({
                contextMenu: !this.state.contextMenu,
                contextMenuNumber: number
            })
        }else{
            this.setState({
                contextMenu: !this.state.contextMenu,
                contextMenuNumber: number
            })
        }

    }

    render() {
        const {gameTableContext, applicationContext} = this.props;
        return (
            <div className={styles.Table}>
                <div className={styles.Monitor}>
                    <div className={applicationContext.translator("direction") === "rtl" && "no-letter-space font-bold"}>{this.state.display?this.state.display:(
                        <React.Fragment>
                            <span>{this.state.date}</span><br/>
                            <span className={styles.TimerText}>{this.state.timer}</span>
                        </React.Fragment>
                    )}</div>
                </div>
                {gameTableContext.state.lockTable && <div className={classNames(styles.OverlayLock, gameTableContext.state.isMobileVersion && gameTableContext.state.waitNextRound && styles.MobileOverlayLock)}>
                    {gameTableContext.state.isMobileVersion && gameTableContext.state.waitNextRound && <div className={styles.MobileOverlayWrapper}>
                        <div style={{ marginBottom: "90px" }}>
                            <h5>{applicationContext.translator("Please wait")}<br/>{applicationContext.translator("for the next round")}</h5>
                            <Timer customClass={styles.MobileOverlayTimer} nonZero={true} />
                        </div>
                    </div>}
                </div>}
                <table width={"100%"} style={gameTableContext.state.isMobileVersion ? { height: this.state.height }: null} >
                    {TABLE_NUMBERS.map((row, index) => (
                        <TableRow key={index}>
                            {row.map((cell, i) => {
                                return (
                                    typeof cell.number === "number"?(
                                        <td key={i} colSpan={cell.colSpan} rowSpan={cell.rowSpan}>
                                            <div className={classNames(
                                                styles.CellNumber,
                                                cell.small && styles.TextSmall,
                                                cell.vertical && styles.Vertical,
                                                cell.green && styles.TextGreen,
                                                RED_NUMBERS_OF_TABLE.includes(cell.number) && styles.TextRed,
                                                specialCells(gameTableContext, cell.number) && styles.TableCellActive)}
                                                id={`cell-n-${cell.number}`}
                                                onContextMenu={(e) => this.handleOnContextMenu(e, cell.number)}
                                                onMouseMove={(e) => this.onSelectCell(cell.number)}
                                                onMouseLeave={(e) => this.onClearSelected()}
                                                onClick={(e) => this.onBet( cell.number)}
                                            >
                                                {cell.label}
                                                {this.renderChips(cell.number)}
                                                {!gameTableContext.state.lockTable && this.state.contextMenu && this.state.contextMenuNumber.toString() === cell.number.toString() &&
                                                    <ContextMenu show={true}
                                                            onClose={this.handleOnContextMenu}
                                                            showOnTop={index > 7}
                                                            showOnRight={(i === 0 || i === 1)}
                                                            showOnCenter={(i === 2)}
                                                            number={cell.number}
                                                />}
                                            </div>
                                            {this.renderBorders(cell.number)}
                                        </td>
                                    ):(
                                        cell.number === "icon"?(
                                            cell.func === "chips"?(
                                                <td key={i} colSpan={cell.colSpan} rowSpan={cell.rowSpan} className={styles.MobileIcons}>
                                                    <button className={styles.MobileIcon} onClick={(e) => gameTableContext.handleOpenWindow(cell.func)}>
                                                        {!gameTableContext.state.chipSelectedWindow?(
                                                            <img src={cell.label} width={24}/>
                                                        ):(
                                                            <img src={`${process.env.PUBLIC_URL}/assets/img/chips-${gameTableContext.state.chipSelectedWindow}.png`} width={24}/>
                                                        )}
                                                    </button>
                                                </td>
                                            ):(
                                                <td key={i} colSpan={cell.colSpan} rowSpan={cell.rowSpan} className={styles.MobileIcons}>
                                                    <button className={styles.MobileIcon} onClick={(e) => gameTableContext.handleOpenWindow(cell.func)}><img src={cell.label} width={24}/></button>
                                                </td>
                                            )

                                        ):(
                                            cell.number === "timer"?(
                                                <React.Fragment>
                                                    <td key={i} colSpan={cell.colSpan} rowSpan={cell.rowSpan} className={styles.MobileIcons}>
                                                        <Timer />
                                                    </td>
                                                </React.Fragment>
                                            ):(
                                                <React.Fragment>
                                                    <td key={i} colSpan={cell.colSpan} rowSpan={cell.rowSpan}
                                                        className={classNames(
                                                            styles.CellNumber,
                                                            cell.small && styles.TextSmall,
                                                            cell.vertical && styles.Vertical,
                                                            cell.green && styles.TextGreen,
                                                            (cell.label === "RED" || cell.label === "BLACK") && styles.DiamondCell,
                                                            RED_NUMBERS_OF_TABLE.includes(cell.number) && styles.TextRed,
                                                            specialCells(gameTableContext, cell.number) && styles.TableCellActive)}
                                                        id={`cell-f-${cell.number}`}
                                                        onContextMenu={(e) => this.handleOnContextMenu(e, cell.number)}
                                                        onMouseMove={(e) => this.onSelectFormulas(cell.number)}
                                                        onMouseLeave={(e) => this.onClearSelected()}
                                                        onClick={(e) => this.onBet( cell.number)}
                                                    >
                                                        {(cell.label === "RED" || cell.label === "BLACK")?(
                                                            (cell.label === "RED")?(
                                                                <img src={(gameTableContext.state.wonCells.includes("RED"))?RedLozengeGlow:RedLozenge} className={styles.Diamond} />
                                                            ):(
                                                                <img src={(gameTableContext.state.wonCells.includes("BLACK"))?BlackLozengeGlow:BlackLozenge} className={styles.Diamond}/>
                                                            )
                                                        ):(cell.label)}
                                                        {!gameTableContext.state.lockTable && this.state.contextMenu && this.state.contextMenuNumber.toString() === cell.number.toString() &&
                                                        <ContextMenu show={true}
                                                                    onClose={this.handleOnContextMenu}
                                                                    showOnTop={index > 7}
                                                                    showOnRight={(i === 0 || i === 1)}
                                                                    showOnCenter={(i === 2)}
                                                                    number={cell.number}
                                                        />}
                                                        {this.renderChips(cell.number)}
                                                        {this.renderBorders(cell.number)}
                                                    </td>
                                                </React.Fragment>
                                            )
                                        )
                                    )

                                )
                            })}

                        </TableRow>    
                    ))}
                </table>
            </div>
        );
    }
}

export default withApplicationContext(withGameTableContext(Table));